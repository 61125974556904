import React from "react";
import { Card } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Skills.css";

function Skills() {
  const skills = [
    {
      name: "html",
      img: process.env.PUBLIC_URL + "/assets/images/skills/html.png",
      score: 95,
    },
    {
      name: "css",
      img: process.env.PUBLIC_URL + "/assets/images/skills/css.png",
      score: 90,
    },
    {
      name: "php",
      img: process.env.PUBLIC_URL + "/assets/images/skills/php.png",
      score: 90,
    },
    {
      name: "mysql",
      img: process.env.PUBLIC_URL + "/assets/images/skills/mysql.png",
      score: 90,
    },
    {
      name: "bootstrap",
      img: process.env.PUBLIC_URL + "/assets/images/skills/bootstrap.png",
      score: 85,
    },
    {
      name: "javascript",
      img: process.env.PUBLIC_URL + "/assets/images/skills/javascript.png",
      score: 85,
    },
    {
      name: "vuejs",
      img: process.env.PUBLIC_URL + "/assets/images/skills/vuejs.png",
      score: 75,
    },
    {
      name: "react",
      img: process.env.PUBLIC_URL + "/assets/images/skills/react.png",
      score: 75,
    },
    {
      name: "docker",
      img: process.env.PUBLIC_URL + "/assets/images/skills/docker.png",
      score: 60,
    },
  ];

  const Banner = ({ skills }) => {
    return (
      <div className="inner">
        <div className="wrapper">
          <section className="skillSection">
            {skills.map((skill, i) => (
              <div key={i}>
                <img className="img" src={skill.img} alt={skill.name} />
                <CircularProgressbar value={skill.score} text={`${skill.score}%`} className="p-2" />
              </div>
            ))}
          </section>
          <section className="skillSection">
            {skills.map((skill, i) => (
              <div key={i}>
                <img className="img" src={skill.img} alt={skill.name} />
                <CircularProgressbar value={skill.score} text={`${skill.score}%`} className="p-2" />
              </div>
            ))}
          </section>
          <section className="skillSection">
            {skills.map((skill, i) => (
              <div key={i}>
                <img className="img" src={skill.img} alt={skill.name} />
                <CircularProgressbar value={skill.score} text={`${skill.score}%`} className="p-2" />
              </div>
            ))}
          </section>
        </div>
      </div>
    );
  };

  return (
    <Card className="mx-auto shadow">
      <Card.Header>
        <h2 className="text-center">Skills</h2>
      </Card.Header>
      <Card.Body>
        <Banner skills={skills} />
      </Card.Body>
    </Card>
  );
}

export default Skills;

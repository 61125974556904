import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import * as Icon from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import CenterModal from "../CenterModal";

function Footer() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="mt-auto">
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <div className="d-flex w-100">
            <div className="d-flex flex-column w-50">
              <Navbar.Text className="text-light align-self-center">
                <h2>Menu</h2>
              </Navbar.Text>
              <Nav className="d-flex flex-column align-self-center">
                <LinkContainer to="/">
                  <Nav.Link>About me</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/skills">
                  <Nav.Link>Skills</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/projects">
                  <Nav.Link>Projects</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={() => setModalShow(true)}>Contact me</Nav.Link>
              </Nav>
            </div>
            <div className="d-flex flex-column w-50">
              <Navbar.Text className="text-light align-self-center">
                <h2>Socials</h2>
              </Navbar.Text>
              <div className="d-flex justify-content-center">
                <Nav>
                  <Nav.Link href="https://github.com/natharyu" target="_blank">
                    <Icon.Github size="32" />
                  </Nav.Link>
                  <Nav.Link href="https://www.linkedin.com/in/anthony-dewitte-le-brun-600124225/" target="_blank">
                    <Icon.Linkedin size="32" />
                  </Nav.Link>
                </Nav>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <CenterModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

export default Footer;

import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

function CenterModal(props) {
  const form = useRef();
  const [show, setShow] = useState(true);
  const [alertVariant, setAlertVariant] = useState(null);
  const [alertContent, setAlertContent] = useState(null);

  function handleClose() {
    setAlertVariant("light");
    setAlertContent("");
    setShow(true);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_bwn0qqb", "template_jb0dk57", form.current, "iq8iTsQa_1K4aIfac").then(
      (result) => {
        setAlertVariant("success");
        setAlertContent("Your message successfully send !");
        setShow(true);
      },
      (error) => {
        setAlertVariant("danger");
        setAlertContent("Oops! an error occured! please try later.");
        setShow(true);
      }
    );
  };
  // reset de lalert quand on ferme la modale
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter">Contact me</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={form} onSubmit={sendEmail}>
          <FloatingLabel controlId="floatingInput" label="Your Email" className="mb-3">
            <Form.Control type="email" placeholder="name@example.com" name="user_email" required />
          </FloatingLabel>

          <FloatingLabel controlId="floatingTextarea" label="Your message" className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Leave your message here"
              style={{ height: "150px" }}
              name="message"
              required
            />
          </FloatingLabel>
          <div className="d-flex justify-content-center">
            <Button type="submit" variant="primary" className="w-25 m-1 p-3" size="lg">
              Submit
            </Button>
            <Alert show={show} variant={alertVariant} className="w-75 m-1 text-center">
              {alertContent}
            </Alert>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default CenterModal;

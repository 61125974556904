import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CenterModal from "../CenterModal";

function NavBar() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg" sticky="top">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Anthony DEWITTE</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>About Me</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/skills">
                <Nav.Link>Skills</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Projects" id="basic-nav-dropdown">
                <LinkContainer to="/projects">
                  <NavDropdown.Item>All projects</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer to="/projects/1">
                  <NavDropdown.Item>Project 1</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/projects/2">
                  <NavDropdown.Item>Project 2</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/projects/3">
                  <NavDropdown.Item>Project 3</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/projects/4">
                  <NavDropdown.Item>Project 4</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <Nav.Link onClick={() => setModalShow(true)}>Contact me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <CenterModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default NavBar;

import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import CenterModal from "../CenterModal";

function Home() {
  let dateFns = require("date-fns");
  let birthday = "05/11/1993";
  let date = dateFns.parse(birthday, "dd/MM/yyyy", new Date());
  let age = dateFns.differenceInYears(new Date(), date);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <h2 className="text-center">About me</h2>
        </Card.Header>
        <Card.Body className="d-flex flex-column flex-xl-row">
          <Card.Img
            className="w-25 rounded-4 mx-auto shadow"
            src={process.env.PUBLIC_URL + "/assets/images/profil/profil.jpg"}
            alt="My profil picture"
          />
          <Card.Text className="m-2 align-self-center">
            Hello, my name is Anthony DEWITTE, I'm {age} years old. Passionate about IT and development since my
            youngest age, I had decided to keep this field as a passion / hobby. So I continued my studies, first of all
            by taking a Bac STAV (SCIENCES AND TECHNOLOGIES OF AGRONOMY AND LIFE) at the agricultural school Fénelon, to
            be able to do landscaping. A big allergy to pollen declared itself so I could not continue in this area. So
            I resumed my studies with a BTS sound engineer at the SAE Institute. Following this BTS, this sector no
            longer recruiting, I continued to work hoping that one day a place would become available. Time passes, no
            place becomes available. I always develop on my side on my free time. Until I decided to make my passion my
            job. So I started training as a Web integrator developer with the 3WAcademy. The training being now
            completed, I am currently looking for a position as a Web developer, while continuing to improve myself.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-evenly">
          <Link to="/skills">
            <Button variant="outline-primary">More about my skills</Button>
          </Link>
          <Link to="/projects">
            <Button variant="outline-primary">All my projects</Button>
          </Link>
          <Button variant="outline-primary" onClick={() => setModalShow(true)}>
            Contact me
          </Button>
        </Card.Footer>
      </Card>
      <CenterModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Home;

import React from "react";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

function OneProject() {
  const projects = [
    {
      id: 1,
      title: "Home-Cloud",
      img: "/assets/images/projects/project1.jpg",
      desc: "This was a solo project and my final project to be able to validate my training. The duration of this project was 7 days. To validate this training and obtain the diploma, i need to present the 5 basic languages (HTML, CSS, JavaScript, PHP and MySQL). For this, I decided to embark on the development of a cloud. It can be hosted locally or online. To this cloud, I added a TodoList and a weather module that retrieves information from an API to get the weather according to the city you are looking for. An account is available to access the cloud, you can log in with user: admin and password: admin",
      link: "https://project1.dew-dev.fr/",
    },
    {
      id: 2,
      title: "Vue Hub",
      img: "/assets/images/projects/project2.jpg",
      desc: "This project was a solo project that had to be done in 1 day. He was asked to do this to discover the Vue JS framework. The theme of this project was free, so I chose to make a TodoList, a weather module, a film/series search module as well as a module that lists the popular films of the moment. All modules except the TodoList use an API to obtain and process the desired information.",
      link: "https://project2.dew-dev.fr/",
    },
    {
      id: 3,
      title: "Noël Shop",
      img: "/assets/images/projects/project3.jpg",
      desc: "This project was a group project that lasted 2 days. This group consisted of 4 members in total. The theme given for this project was to make a semblance of an e-commerce site. The group voted to make a Christmas themed site. We divided into 2 teams, a front-end team and a back-end team, but while maintaining a spirit of mutual aid. If one of us had any problems there was always someone there to help and vice versa.",
      link: "https://project3.dew-dev.fr/",
    },
    {
      id: 4,
      title: "RPG Character Maker",
      img: "/assets/images/projects/project4.jpg",
      desc: "This is a role-playing type character creator (Dungeon & dragon, etc...) This project was the first project of the training and a group project to allow the discovery of work with several people and to implement our first knowledge of HTML, CSS, JavaScript, PHP and MySQL. A team of two people who were more comfortable in the front-end focused on the front and the other two more comfortable in the back-end took care of the back.",
      link: "https://project4.dew-dev.fr/",
    },
  ];
  let params = useParams();
  let project = projects.find((e) => e.id == params.id);

  return (
    <Card className="w-75 mx-auto">
      <Card.Header>
        <h2 className="text-center">{project.title}</h2>
      </Card.Header>
      <Card.Body>
        <Card.Img className="rounded-4" src={process.env.PUBLIC_URL + project.img} />
        <Card.Body>
          <Card.Title>{project.title}</Card.Title>
          <Card.Text>{project.desc}</Card.Text>
          <div className="d-flex justify-content-evenly mx-auto">
            <Link to="/projects">
              <Button variant="primary" className="m-1">
                Back to all projects
              </Button>
            </Link>
            <Link to={project.link} target="_blank">
              <Button variant="primary" className="m-1">
                Visit this project
              </Button>
            </Link>
          </div>
        </Card.Body>
      </Card.Body>
    </Card>
  );
}

export default OneProject;

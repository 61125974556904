import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Card, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

function Projects() {
  const CaptionStyle = {
    background: "rgba(255, 255, 255, 0.7)",
    borderRadius: "8px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(8)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
  };
  return (
    <Card className="w-75 mx-auto">
      <Card.Header>
        <h2 className="text-center">All of my projects just here.</h2>
      </Card.Header>
      <Carousel indicators={false} slide={true} touch={true}>
        <Carousel.Item>
          <img src={process.env.PUBLIC_URL + "/assets/images/projects/project1.jpg"} alt="First slide" />
          <Carousel.Caption style={CaptionStyle}>
            <h3 className="text-dark">Home-Cloud</h3>
            <div className="d-flex justify-content-evenly mx-auto">
              <Link to="/projects/1">
                <Button variant="primary" className="m-1">
                  More info...
                </Button>
              </Link>
              <Link to="https://project1.dew-dev.fr/" target="_blank">
                <Button variant="primary" className="m-1">
                  Visit this project
                </Button>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={process.env.PUBLIC_URL + "/assets/images/projects/project2.jpg"} alt="Second slide" />

          <Carousel.Caption style={CaptionStyle}>
            <h3 className="text-dark">Vue Hub</h3>
            <div className="d-flex justify-content-evenly mx-auto">
              <Link to="/projects/2">
                <Button variant="primary" className="m-1">
                  More info...
                </Button>
              </Link>
              <Link to="https://project2.dew-dev.fr/" target="_blank">
                <Button variant="primary" className="m-1">
                  Visit this project
                </Button>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={process.env.PUBLIC_URL + "/assets/images/projects/project3.jpg"} alt="Third slide" />

          <Carousel.Caption style={CaptionStyle}>
            <h3 className="text-dark">Noël Shop</h3>
            <div className="d-flex justify-content-evenly mx-auto">
              <Link to="/projects/3">
                <Button variant="primary" className="m-1">
                  More info...
                </Button>
              </Link>
              <Link to="https://project3.dew-dev.fr/" target="_blank">
                <Button variant="primary" className="m-1">
                  Visit this project
                </Button>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={process.env.PUBLIC_URL + "/assets/images/projects/project4.jpg"} alt="Third slide" />

          <Carousel.Caption style={CaptionStyle}>
            <h3 className="text-dark">RPG Character maker</h3>
            <div className="d-flex justify-content-evenly mx-auto">
              <Link to="/projects/4">
                <Button variant="primary" className="m-1">
                  More info...
                </Button>
              </Link>
              <Link to="https://project4.dew-dev.fr/" target="_blank">
                <Button variant="primary" className="m-1">
                  Visit this project
                </Button>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Card>
  );
}

export default Projects;

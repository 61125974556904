import React from "react";
import { Alert, Container } from "react-bootstrap";

function Error404() {
  return (
    <Container>
      <Alert variant="danger">
        <Alert.Heading>Oops! You got an error!</Alert.Heading>
        <p>This page doesn't exist !</p>
      </Alert>
    </Container>
  );
}
export default Error404;
